.info-header {
    background-color: #3285ff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    color: #fff;
}

.info-header__link {
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.info-header__link:hover {
    color: #fff;
}

.info-container {
    background: url(../assets/images/photo_2024-01-09_11-30-43.jpg);
    background-size: cover;
    background-position: center;
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-content {
    background: rgba(255, 255, 255, 0.7);
    width: 75%;
    padding: 20px;
    border-radius: 10px;
    text-align: start;
    font-size: 20px;
}

.info-content h2 {
    text-align: center;
}