.wrapperButton {
  .file_block {
    // border: 1px solid;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}



.overlayWrapper {
  position: relative;
  width: 300px;
  height: 350px;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .overlay {
    position: absolute;
    padding: 20px;
    top: 0;
    font-size: 14px;
    width: 100%;
    height: 100%;
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.5s ease, transform 0.5s ease;
  }

  &:hover .overlay {
    transform: translateY(0%);
    opacity: 1;
    background-color: rgb(255, 255, 255);
  }
}



